@font-face {
  font-family: 'Panoptikon';
  src: url('../assets/fonts/panoptikon-v11.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Platos Hat';
  src: url('../assets/fonts/platoshat.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} 